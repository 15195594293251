import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from '../components/layout/Layout';
import Img from '../components/widgets/Img';

import english from '../assets/english.jpg';
import french from '../assets/french.jpg';
import math from '../assets/math.jpg';
import physics from '../assets/physics.jpg';
import './pages-css/services.css';

const services = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learning Curve | Services</title>
            </Helmet>
            <Fragment>
                <div className="jumbotron mb-0 bg-services">
                    <div className="container">
                        <h4 className="display-4 text-white bold">Professional Tutoring</h4>
                        <p className="text-light lead">Learning Curve can help you take the next step in your education</p>
                        <hr className="my-4 hr" />
                        
                    </div>
                </div>
                <div className="bg-blue-grad">
                    <div className="container">
                        <div className="align-center">
                            <p style={{fontSize: '1.6em'}} className="text-white py-4 mb-0">Our team at Learning Curve has committed instructors who have completed all aspects of the Ontario Curriculum and have years of experience instructing students from all school boards and programs.</p>
                        </div>
                    </div>
                </div>
                <div className="tri"></div>

                <div id="services-english" name="services-english" className="container my-5">
                    <div className="row mb-5">
                        <div className="col-lg center-vert marg fade-right order-lg-12">
                            <h1 className="text-dark bold">English:</h1>
                            <ul className="list-group shadow-sm mb-3">
                                <li className="list-group-item">Grade 11 English</li>
                                <li className="list-group-item">Grade 12 English</li>
                            </ul>
                            <Link className="btn btn-yellow" to="/contact">Contact Us</Link>
                        </div>
                        <div className="col-lg order-lg-1">
                            <Img url={english} />
                        </div>
                    </div>
                    <p style={{fontSize: '1.2em'}} className="text-dark">The Ontario Curriculum for high school English is required to build upon various literacy skills in preparation for the workplace or post-secondary education. At Learning Curve, we value twenty-first century learning to effectively increase the complexity of students' literacy.</p>
                    <p style={{fontSize: '1.2em'}} className="text-dark">Being able to communicate yourself effectively, whether it be through oral presentations or essay writing, is a complicated task for young learners. At Learning Curve our team of professionals take the Ontario Curriculum standards and apply post-secondary concepts to heighten the performance levels of students.</p>
                    <hr/>
                    <div className="row">
                        <div className="col-lg">
                            <h4>Writing</h4>
                            <ul className="items">
                                <li>Essay writing</li>
                                <li>Report writing</li>
                                <li>Poetry and more</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h4>Reading</h4>
                            <ul className="items">
                                <li>Novel studies</li>
                                <li>Shakespeare/plays</li>
                                <li>Articles and more</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h4>Oral Communication</h4>
                            <ul className="items">
                                <li>Presentation skills</li>
                                <li>How to make powerpoints and more</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="services-french" name="services-french" className="container my-5">
                    <div className="row mb-5">
                        <div className="col-lg center-vert marg fade-right">
                            <h1 className="text-dark bold">French:</h1>
                            <ul className="list-group shadow-sm mb-3">
                                <li className="list-group-item">Grade 9 Core, Immersion</li>
                                <li className="list-group-item">Grade 10 Core, Immersion</li>
                                <li className="list-group-item">Grade 11 Core, IB, AP, Immersion</li>
                                <li className="list-group-item">Grade 12 Core, IB, AP, Immersion</li>
                            </ul>
                            <Link className="btn btn-yellow" to="/contact">Contact Us</Link>
                        </div>
                        <div className="col-lg">
                            <Img url={french} />
                        </div>
                    </div>
                    <p style={{fontSize: '1.2em'}} className="text-dark">In Canada, commonly overlooked career paths are those that require the French language. By obtaining a high school education of French, students can begin to expand their job opportunities. Whether it be French immersion, AP French courses or even core French, all students are capable.</p>
                    <p style={{fontSize: '1.2em'}} className="text-dark">French language acquisition becomes increasingly difficult when students reach secondary school. Many students find it hard to keep up with difficulty levels and deem themselves incapable of learning the language. However, this is not the case with most students. Rather students find difficulty in learning because they lack the fundamentals that are required to learn any language.</p>
                    <hr/>
                    <div className="row">
                        <div className="col-lg">
                            <h5 className="h-sm">Oral Communication</h5>
                            <ul className="items-sm">
                                <li>Pronunciation</li>
                                <li>Presentation skills</li>
                                <li>One on one communication</li>
                                <li>Participation and more</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Reading</h5>
                            <ul className="items-sm">
                                <li>Short passages</li>
                                <li>French novels</li>
                                <li>Reading strategies and more</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Writing</h5>
                            <ul className="items-sm">
                                <li>Vocabulary, grammar, and syntax</li>
                                <li>Essay writing</li>
                                <li>Report writing</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Listening</h5>
                            <ul className="items-sm">
                                <li>Identifying sounds</li>
                                <li>Conversational skills</li>
                                <li>Use of French media</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="services-physics" name="services-physics" className="container my-5">
                    <div className="row mb-5">
                        <div className="col-lg center-vert marg fade-right order-lg-12">
                            <h1 className="text-dark bold">Physics:</h1>
                            <ul className="list-group shadow-sm mb-3">
                            <li className="list-group-item">Grade 10 Physics</li>
                            <li className="list-group-item">Grade 11 Physics</li>
                            <li className="list-group-item">Grade 12 Physics</li>
                            </ul>
                            <Link className="btn btn-yellow" to="/contact">Contact Us</Link>
                        </div>
                        <div className="col-lg order-lg-1">
                            <Img url={physics} />
                        </div>
                    </div>
                    <p style={{fontSize: '1.2em'}} className="text-dark">To question, think, and understand things around us, is an innate quality that exists in all humans, and the journey to explore nature and the universe has humble beginnings; starting with physics introduced in the high school curriculum.</p>
                    <p style={{fontSize: '1.2em'}} className="text-dark">At Learning Curve, we understand that physics is a challenging subject to learn at first. The universe is under no obligation to make sense to us. Physics, being the study of the universe, means that some concepts can seem strange to us, but that’s just how the world works! That is why our focus at Learning Curve will be to sharpen critical thinking skills, explain concepts wherever necessary and iron out all details to present the students with a perfect picture of the subject. The first step to learning the material is a good explanation, then reading the texts, followed by ample practice and support!</p>
                    <hr/>
                    <div className="row">
                        <div className="col-lg">
                            <h4>Grade 11</h4>
                            <ul className="items">
                                <li>Kinematics; Newton’s laws of motion</li>
                                <li>Electricity and magnetism</li>
                                <li>Fundamental forces</li>
                                <li>Energy and society</li>
                                <li>Thermodynamics and nuclear energy</li>
                                <li>Waves and sound</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h4>Grade 12</h4>
                            <ul className="items">
                                <li>The principles of dynamics</li>
                                <li>Circular motion</li>
                                <li>Energy and momentum</li>
                                <li>Gravitational, electric and magnetic fields</li>
                                <li>The wave nature and duality of light</li>
                                <li>Revolutions in modern physics</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="services-math" name="services-math" className="container my-5">
                    <div className="row mb-5">
                        <div className="col-lg center-vert marg fade-right">
                            <h1 className="text-dark bold">Math:</h1>
                            <ul className="list-group shadow-sm mb-3">
                                <li className="list-group-item">Grade 9/10 Math</li>
                                <li className="list-group-item">Grade 11 Functions</li>
                                <li className="list-group-item">Grade 12 Advanced Functions, Calculus</li>
                            </ul>
                            <Link className="btn btn-yellow" to="/contact">Contact Us</Link>
                        </div>
                        <div className="col-lg">
                            <Img url={math} />
                        </div>
                    </div>
                    <p style={{fontSize: '1.2em'}} className="text-dark">Mathematics is one of the most striking skills one can learn in our diversifying world. Everyday more and more jobs require some type computational competence. Mathematics is an artistry we value heavily at Learning Curve. Our instructors find the best ways to present learning material by focusing on problem solving, reasoning and communicating knowledge and understanding.</p>
                    <p style={{fontSize: '1.2em'}} className="text-dark">Like any skill development, we prioritize acquiring the fundamentals of mathematics. We use resources that are beneficial for virtual education and student success. Learning how to effectively use graphic calculators, online graphing calculators such as desmos, and more.</p>
                    <hr/>
                    <div className="row">
                        <div className="col-lg">
                            <h5 className="h-sm">Grade 9-10</h5>
                            <ul className="items-sm">
                                <li>Solve algebraic expressions</li>
                                <li>Explore linear equations</li>
                                <li>Solve systems of linear equations</li>
                                <li>Explore quadratic relations</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Grade 11</h5>
                            <ul className="items-sm">
                                <li>Explore characteristics of functions</li>
                                <li>Exponential functions</li>
                                <li>Discrete functions</li>
                                <li>Trigonometric functions</li>
                                <li>Personal finance </li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Advanced Fucntions</h5>
                            <ul className="items-sm">
                                <li>Polynomial functions</li>
                                <li>Rational functions</li>
                                <li>Trigonometric functions</li>
                                <li>Logarithmic functions</li>
                            </ul>
                        </div>
                        <div className="col-lg">
                            <h5 className="h-sm">Calculus</h5>
                            <ul className="items-sm">
                                <li>Rate of change</li>
                                <li>Derivatives and their applications</li>
                                <li>Three-dimensional space</li>
                                <li>Application of vectors</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        </Layout>
        
    )
}

export default services

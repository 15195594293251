import React from 'react'

const Img = ({ url }) => {

    const imgStyle = {
        width: '100%',
        borderRadius: '0.2rem'
    }

    return (
        <div className="card shadow" >
            <img style={imgStyle} src={url} alt="img"/>
        </div>
    )
}

export default Img
